import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { ContentContainer } from '../components'
import { ExternalContent } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { mq } from './../utility'

import { fairtouchConfig } from './../config'

const InformationMaterialPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [_, setLoaded] = React.useState(false)

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const lccc = 'en-US'

  return (
    <PageFrame
      {...params}
      pageName="informationMaterial"
      isTop
      perectScroll={false}
    >
      <FairtouchContentContainer fullwidth>
        <FairtouchContainer>
          <ExternalContent
            uri={`${fairtouchConfig.uri}/formnext21/webplayer/index.html?device=Formne_3&langswitch=false&logo=false&lccc=${lccc}`}
          />
        </FairtouchContainer>
      </FairtouchContentContainer>
    </PageFrame>
  )
}

export default InformationMaterialPage

const FairtouchContentContainer = styled(ContentContainer)(
  ({ theme: { colors, grid } }) => `
    margin-top:70px;

    ${mq[3]}{
      margin-top:155px;
    }
    ${mq[4]}{
      margin-top:145px;
    }
  `
)

const FairtouchContainer = styled.div(
  ({ theme: { colors, grid } }) => `
    height:calc(100vh - 70px);
    ${mq[3]}{
      height:calc(100vh - 105px);
    }
     ${mq[4]}{
      height:calc(100vh - 95px);
    }
    width:100%;
  `
)
